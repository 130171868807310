<template>
  <div class="template-list">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <h2 v-if="this.$route.params.code === 'new'">
              Добавление типового проекта
            </h2>
            <h2 v-else>Редактирование типового проекта</h2>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Код"
                  :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                  v-model="standardProject.code"
                  @input="requestStandardProjectUpdate"
                />
              </CCol>
            </CRow>
            <div v-if="standardProject.id">
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Название"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                    v-model="standardProject.name"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CTextarea
                    label="Описание"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                    rows="10"
                    v-model="standardProject.description"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CTextarea
                    label=" Материалы"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                    rows="10"
                    v-model="standardProject.materials"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CTextarea
                    label="Монтаж"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                    rows="10"
                    v-model="standardProject.installation"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Стоимость оборудования"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.equipment_price"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Стоимость установки"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.installation_price"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Вес"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.weight"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Ширина"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.width"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Длина"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.length"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Высота"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.height"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Вместимость чел."
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-4' }"
                    v-model="standardProject.capacity"
                    @input="requestStandardProjectUpdate"
                  />
                </CCol>
              </CRow>

              <CRow
                v-for="(fileField, index) in fileFields"
                v-bind:key="fileField"
              >
                <CCol>
                  <CInputFile
                    :label="fieldLabels[fileField]"
                    :data-code="fileField"
                    placeholder=""
                    @change="uploadFile"
                  />
                  <div v-if="files[fileField]">
                    <img
                      v-if="files[fileField].match(/(jpe?g|png|gif|svg|webp)/)"
                      style="max-width: 400px"
                      :src="files[fileField]"
                    />
                    <a v-else :href="files[fileField]" target="_blank"
                      >Скачать</a
                    >
                  </div>
                  <br />
                  <br />
                  <br />
                </CCol>
              </CRow>
              <CRow v-if="equipmentItems">
                <CCol>
                  <h3>Связанные комплексы</h3>
                  <v-select
                    class="v-select w-100"
                    label="code"
                    :options="equipmentItems"
                    @input="addEquipmentItemToStandardProject"
                    placeholder="Выберите комплекс..."
                    :clearSearchOnSelect="true"
                    :closeOnSelect="true"
                  >
                    <template
                      class="w-100"
                      #option="{ code, name, model_preview }"
                    >
                      <div
                        class="list-item-container"
                        style="border-top: 1px solid #d8dbe0"
                      >
                        <h3
                          style="
                            margin: 0;
                            grid-row: 1;
                            grid-column: 1;
                            font-size: 18px;
                            grid-auto-rows: minmax(50px, auto);
                          "
                        >
                          {{ code }}
                        </h3>
                        <p
                          style="
                            text-alian: center;
                            grid-row: 2;
                            grid-column: 1/3;
                            white-space: normal;
                            grid-auto-rows: minmax(50px, auto);
                            font-size: 10px;
                            margin-bottom: 5px;
                          "
                        >
                          {{ name }}
                        </p>
                        <img
                          style="
                            grid-row: 1/2;
                            grid-column: 2;
                            width: 100%;
                            alian-self: center;
                            display: grid;
                          "
                          class="image-scale"
                          :src="model_preview"
                        />
                        <button
                          class="c-button"
                          style="
                            padding: 3px 5px;
                            grid-row: 3;
                            grid-column: 1/3;
                            font-size: 14px;
                          "
                        >
                          добавить
                        </button>
                      </div>
                    </template>
                  </v-select>
                  <div v-if="standardProjectEquipmentItems">
                    <ul>
                      <li v-for="(standardProjectEquipmentItem, index) in standardProjectEquipmentItems" :key="index">
                        <div>{{ getEquipmentItemById(standardProjectEquipmentItem.equipment_item_id).code }}</div>
                        <div><img :src="getEquipmentItemById(standardProjectEquipmentItem.equipment_item_id).model_preview"></div>
                        <div><CButton @click="removeEquipmentItemFromStandardProject(standardProjectEquipmentItem.key)">Удалить</CButton></div>
                      </li>
                    </ul>
                  </div>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<style scoped>
.list-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content 50px;
  justify-content: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px;
  column-gap: 5px;
}

.c-button {
  min-width: min-content;
  appearance: none;
  border: 0;
  background: #4d4d4d;
  color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0.25rem;
}

.c-button:hover {
  background: #000;
}

.c-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.3);
}

.image-showcase {
  grid-row: 1/5;
  grid-column: 1;

  width: 150px;
}

.v-select .vs__dropdown-option--highlight {
  background: #fff;
  color: #4d4d4d;
}
</style>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  name: "StandardProject",
  components: { vSelect },
  data() {
    return {
      standardProjects: null,
      equipmentItems: null,
      standardProjectEquipmentItems: null,
      standardProject: {},
      unsubscribe: null,
      fileFields: [
        "offer",
        "preview",
        "modelRender",
        "modelAutocad",
        "modelPreview",
        "installationScheme",
      ],
      fieldLabels: {
        offer: "Коммерческое предложение",
        preview: "Картинка",
        modelRender: "Модель для рендера",
        modelAutocad: "Схема для Автокада",
        modelPreview: "Модель для предпросмотра",
        installationScheme: "Монтажная схема",
      },
      backendMapping: {
        offer: "offer",
        preview: "preview",
        modelRender: "model_render",
        modelAutocad: "model_autocad",
        modelPreview: "model_preview",
        installationScheme: "installation_scheme",
      },
      files: {},
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "standardProjects/getAllSuccess") {
        this.standardProjects = mutation.payload;
        if (this.$route.params.code !== "new") {
          for (const standardProject of this.standardProjects) {
            
            if (this.$route.params.code === standardProject.code) {
              this.standardProject = standardProject;
              this["standardProjects/getEquipmentItems"]({id: standardProject.id})
              for (const fileField of this.fileFields) {
                this.files[fileField] =
                  this.standardProject[this.backendMapping[fileField]];
              }
              break;
            }
          }
        }
      }
      if (mutation.type === "standardProjects/createSuccess") {
        this.$router.push(`/standard-projects/${mutation.payload.code}`)
      }

      if (mutation.type === "equipmentItems/getAllSuccess") {
        this.equipmentItems = mutation.payload;
      }
      if (mutation.type === "standardProjects/getEquipmentItemsSuccess") {
        this.standardProjectEquipmentItems = mutation.payload;
      }
      if (mutation.type === "standardProjects/addEquipmentItemSuccess") {
        this.standardProjectEquipmentItems = mutation.payload;
      }
      if (mutation.type === "standardProjects/removeEquipmentItemSuccess") {
        this.standardProjectEquipmentItems = mutation.payload;
      }
    });
    this["standardProjects/getAll"]();
    this["equipmentItems/getAll"]();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },

  methods: {
    ...mapActions([
      "equipmentItems/getAll",
      "standardProjects/getAll",
      "standardProjects/create",
      "standardProjects/update",
      "standardProjects/saveFile",
      "standardProjects/getEquipmentItems",
      "standardProjects/addEquipmentItem",
      "standardProjects/removeEquipmentItem",
    ]),

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    requestStandardProjectUpdate: debounce(function () {
      this.updateStandardProject();
    }, 1500),

    updateStandardProject() {
      let method;
      if (this.standardProject.id) {
        method = "standardProjects/update";
      } else {
        method = "standardProjects/create";
      }
      const dataToSend = Object.assign({}, this.standardProject);
      for (const fileField of this.fileFields) {
        delete dataToSend[fileField];
      }
      this[method](dataToSend);
    },
    uploadFile(files, event) {
      const file = files[0];
      const propertyName = event.target.dataset.code;
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          const newFiles = Object.assign({}, this.files);
          newFiles[propertyName] = reader.result;
          saveThis.files = newFiles;
          saveThis["standardProjects/saveFile"]({
            id: saveThis.standardProject.id,
            fileType: saveThis.backendMapping[propertyName],
            dataUrl: saveThis.files[propertyName],
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
    addEquipmentItem(equimentItemId) {
      this["standardProjects/addEquipmentItem"]({
        standardProjectId: this.standardProject.id,
        equipmentItemId: equimentItemId,
      });
    },
    removeEquipmentItem(equimentItemKey) {
      this["standardProjects/removeEquipmentItem"]({
        standardProjectId: this.standardProject.id,
        equipmentItemKey: equimentItemKey,
      });
    },

    addEquipmentItemToStandardProject(item) {
      this.addEquipmentItem(item.id);
    },

    removeEquipmentItemFromStandardProject(key) {
      this.removeEquipmentItem(key);

    },

    getEquipmentItemById(id) {
      for (const equipmentItem of this.equipmentItems) {
        if (equipmentItem.id === id) {
          return equipmentItem
        }
      }
      return {}
    }
  },
};
</script>
